.ImageGrid {
    margin: 2vw auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2vw;
    transition: ease width .5s;
    width: 70%;
}

@media only screen and (max-width: 800px) {
    .ImageGrid {
        width: 95%;
    }
}
.ImageGridBox {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius:10%;
}

.ImageGridBox:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.ImageGridBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: transform .8s;
}

.ImageGridBox img:hover {
    transform: scale(1.1);
}

.ImageViewBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.ImageViewImageWrapper {
    display: flex;
    position: relative;
    width: content-box;
    justify-content: center;
    align-items: center;
    margin: 5vh auto;
}

.ImageViewImageWrapper img {
    object-fit: cover;
    max-height: 85vh;
    max-width: 90vw;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
}

#ImageViewImageDescription {
    position: absolute;
    width: calc(100% - 20px);
    left: 0;
    padding: 10px;
    overflow: hidden;
    background-color: white;
    font-family: Bahnschrift, sans-serif;
}

#ImageViewImageRight {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: end;
    top: 0;
    right: 0;
    height: 100%;
    width: 30%;
    opacity: 0;
}

#ImageViewImageRight:hover {
    opacity: 1;
}

#ImageViewImageRight img {
    height: 15%;
    padding-right: 10%;
    border: none;
    box-shadow: none;
    opacity: 1;
}

#ImageViewImageLeft {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: start;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    opacity: 0;
}

#ImageViewImageLeft:hover {
    opacity: 1;
}

#ImageViewImageLeft img {
    height: 15%;
    padding-left: 10%;
    border: none;
    box-shadow: none;
    opacity: 1;
}

#ImageViewImage {
    position: relative;
    margin: 0;
}

#ImageViewLeftArrow {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
}

#ImageViewLeftArrow img {
    width: 3vw;
    object-fit: contain;
    max-width: 10vh;
    max-height: 15vh;
    border: 0;
    box-shadow: none;
}

#ImageViewRightArrow {
    position: relative;
    right: 0;
    top: 0;
    margin: 0;
}

#ImageViewRightArrow img {
    width: 3vw;
    object-fit: contain;
    max-width: 10vh;
    max-height: 15vh;
    border: 0;
    box-shadow: none;
}
