#travelsDestinations{
    position: absolute;
}

.travelDestinationLabel{
    z-index: 6;
}

.travelDestinationLabelWrapperOnHover{
    padding-bottom: 1vh;
    transition: transform 0.7s ease;
    -webkit-transition: transform 0.7s ease;
    -ms-transform: scale(1.1) translateX(-5%) translateY(-5%);
    transform: scale(1.1) translateX(-5%) translateY(-5%);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
    from {transform: translateY(2vh);}
    to {transform: translateY(0);}
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
    from {transform: translateY(2vh);}
    to {transform: translateY(0);}
}

.travelDestinationLabel{
    width: fit-content;
    position: absolute;
    font-family: Bahnschrift, sans-serif;
    color: white;
    padding: 0;
    opacity: 0;
    background-clip: padding-box;
     animation: fadein 1s forwards;
    -webkit-animation: fadein 1s forwards;
    animation-iteration-count: 1;
    animation-delay: 0.7s;
}

@media only screen and (min-width: 850px) {
    .travelDestinationLabelWrapper1{
        transform: rotate(65deg);
        position:relative;
    }

    .travelDestinationLabelWrapperAnimation{
        transition: margin-top 0.7s ease, margin-left 0.7s ease, padding-left 0.7s ease;
        -webkit-transition: margin-top 0.7s ease, margin-left 0.7s ease, padding-left 0.7s ease;
    }


    .travelDestinationLabelWrapper2{
        transform: rotate(-45deg);
        transition: margin-top 0.7s ease, margin-left 0.7s ease, padding-left 0.7s ease;
        -webkit-transition: margin-top 0.7s ease, margin-left 0.7s ease, padding-left 0.7s ease;
        position:relative;
    }

    .travelDestinationLabel1{
        z-index: 2;
    }

    .travelDestinationLabel2{
        z-index: 4;
    }

    .travelDestinationLabelWrapperOnHover{
        padding-bottom: 2vh;
    }

    @keyframes move {
        from {transform: translateY(100px);}
        to {transform: translateY(0px);}
    }

    @-webkit-keyframes move {
        from {transform: translateY(100px);}
        to {transform: translateY(0px);}
    }

    .travelDestinationLabel{
        opacity: 1;
        animation: move 2s forwards;
        -webkit-animation: move 2s forwards;
        animation-delay: 0s;
        animation-iteration-count: 1;
        transition: margin-top 0.7s ease, margin-left 0.7s ease, padding-left 0.7s ease;
        -webkit-transition: margin-top 0.7s ease, margin-left 0.7s ease, padding-left 0.7s ease;
    }
}

.travelDestinationLabelWrapper{
overflow: hidden;
white-space: nowrap;
text-overflow:ellipsis;
height: 100%;
transform-origin: top left;
background-clip: padding-box;
}