#GalleryWrapper{
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    background-color: mintcream;
}

#GalleryHeader{
    font-family: Bahnschrift, sans-serif;
    font-size: 3vh;
    margin: 4vh;
}

#GalleryReturnLink {
    text-decoration: none;
    text-align: left;
}

#GalleryTitle{
    color: dimgray;
    text-align: center;
    font-size: 7vh;
}