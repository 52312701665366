.Travels {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#imagePartTop{
  z-index: 1;
  pointer-events : none;
}

#travelsText{
  z-index: 2;
}

#imagePartBottom{
  z-index: 3;
  pointer-events : none;
}

#imagePartBottomLeft{
  z-index: 5;
  pointer-events: none;
}

.backgroundImage, #travelsText{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
}

#travelsText{
  left: -50px;
}

.backgroundImage{
  left: -100px;
  width: calc(100% + 100px);
}

#destinations{
  position: absolute;
  top: 0;
  left: -75px;
  width: 100%;
  height: 100%;
}

.TravelsLetter{
  position: absolute;
  color: white;
  font-family: Bahnschrift, sans-serif;
  font-weight: bolder;
  animation: move 2s forwards;
  -webkit-animation: move 2s forwards;
  animation-iteration-count: 1;
  padding-right: 4vw;
  background-clip: padding-box;
}


@keyframes move {
  from {transform: translateY(100px);}
  to {transform: translateY(0px);}
}

@-webkit-keyframes move {
  from {transform: translateY(100px);}
  to {transform: translateY(0px);}
}

#T {
  position: absolute;
}

.TravelsLetterOnHover {
  padding-bottom: 4vh;
  margin-top: -4vh;
  margin-left: -0.5vh;
}

.TravelsLetterOffHover {
  margin-top: 1vh;
  margin-left: 0;
}

.TravelsLetterAnimation{
  transition: margin-top 0.3s ease, margin-left 0.3s ease, font-size 0.3s ease;
  -webkit-transition: margin-top 0.3s ease, margin-left 0.3s ease, font-size 0.3s ease;
}